<template>
    <div :class="[{'dark-table':dark},'flex flex-col items-center']">
        <div class="w-full max-w-full my-2 sm:-mx-6 lg:-mx-8">
            <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div v-if="show_search" class="">
                the new search
                <TextInput @input="search_input" type="text" label="Search" v-model="search_value" :required="false" ></TextInput>
            </div>
            <div :class="[{'border-b border-gray-200':!dark,'border-b border-darkPurple':dark},'shadow-lg overflow-hidden sm:rounded-lg']">
                <table :class="[{'divide-gray-200':!dark,'divide-darkPurple':dark},'overflow-x-auto min-w-full divide-']">
                    <thead :class="[{'bg-gray-50':!dark,'bg-darkPurple text-white':dark}]">
                        <tr>
                            <th v-if="multiselect">
                                <input :ref="the_id+'-header-check'" @change="EmitCheckAll($event)" type="checkbox" aria-label="Select All Rows">
                            </th>
                            <th v-for="(column,i) in columns" :key="i" scope="col" :class="[{'text-gray-500':!dark,'text-white':dark},'px-6 py-3 text-left text-xs font-medium  uppercase tracking-wider']">
                                <span v-if="!column.hidelabel" >{{column.label}}</span>
                            </th>
                            <th v-if="canview" scope="col" class="relative px-6 py-3">
                                <span class="sr-only">View</span>
                            </th>
                            <th v-if="candelete" scope="col" class="relative px-6 py-3">
                                <span class="sr-only">Delete</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody :class="[{'bg-white divide-gray-200':!dark,'bg-purple divide-darkPurple text-white':dark},' divide-y ']">
                        <tr v-for="(item,i) in items" :key="i">
                            <td style="text-align:center;" v-if="multiselect">
                                <input :ref="the_id+'-row-'+i" @change="EmitCheck($event,item)" type="checkbox" aria-label="Select Row">
                            </td>
                            <td v-for="(column,it) in columns" :key="'row-column-'+it" class="px-6 py-4">
                                <span v-if="column.custom_property">
                                    <span v-if="item[column.custom_property]">{{item[column.custom_property]}}</span>
                                    <span v-else>N/A</span>
                                </span>
                                <span v-if="column.custom">
                                    {{$parent[column.custom](item)}}
                                </span>
                                <span v-if="column.action">
                                    <ButtonTemplate v-if="column.action_type && column.action_type == 'link'" btn_style="link" :on_click="$parent[column.action]" :click_param="item" class="my-6" type="button">
                                        {{item[column.id]}}
                                    </ButtonTemplate>
                                    <ButtonTemplate v-else btn_style="secondary" :on_click="$parent[column.action]" :click_param="item" class="my-6" type="button">
                                        {{item[column.id]}}
                                    </ButtonTemplate>
                                </span>
                                <span v-if="column.html" v-html="item[column.id]">
                                </span>
                                
                                <span v-if="column.html_custom" v-html="$parent[column.html_custom](item)">
                                </span>
                                <span v-else-if="!column.html && !column.custom && !column.action">
                                    <span v-if="column.parse_date">
                                        {{parseDateCol(item[column.id])}}
                                    </span>
                                    <span v-else>
                                        {{item[column.id]}}
                                    </span>
                                </span>
                            </td>
                            <td v-if="triggerPopup" @click="showModal(item)" class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                              <button style="color:blue">Create Payout</button>
                            </td>
                            <td v-if="canview && !customview" class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <router-link :to="canview+item.id+hashLink" :class="[{'text-indigo-600 hover:text-indigo-900':!dark,'text-yellow hover:underline':dark}]"> View</router-link>
                            </td>
                            <td v-else-if="customview && canview" class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <router-link :to="canview+'/'+item[customview]+hashLink" :class="[{'text-indigo-600 hover:text-indigo-900':!dark,'text-yellow hover:underline':dark}]"> View</router-link>
                            </td>
                            <td v-if="candelete" class="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                <button @click="MaybeDeleteItem(item,i)" class="text-red"> Delete</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swal from 'sweetalert2'
import Button from "@/components/Button.vue";
import moment from 'moment';

export default {
    name: 'Table',
    data: () => ({
        the_id: false,
        loading:false,
    }),
    computed: {
    },
    props: {
        search_value: false,
        search_input: false,
        show_search: false,
        dark: {},
        candelete: {},
        columns: {},
        items: {},
        canview: {}, 
        hashLink: { default: ''},
        customview: {}, 
        multiselect: {},
        triggerPopup: false
    },
    methods: {
        EmitCheckAll(evt) {
            if(evt.target.checked) {
                for(let i in this.items) {
                    this.$refs[this.the_id+'-row-'+i][0].checked = true
                    this.$emit('item:checked',this.items[i])
                }
            }else{
                for(let i in this.items) {
                    this.$refs[this.the_id+'-row-'+i][0].checked = false
                    this.$emit('item:unchecked',this.items[i])
                }
            }
        },
        EmitCheck(evt,item) {
            if(evt.target.checked) {
                this.$emit('item:checked',item)
            }else{
                this.$refs[this.the_id+'-header-check'].checked = false
                this.$emit('item:unchecked',item)
            }
        },
        MaybeDeleteItem(item,index) {
            let that = this
            Swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    that.$emit('item:deleted',{item:item,index:index})
                }
            })
        },
        genId() {
            return Math.random().toString(36).substring(7);
        },
        showModal(item){
            this.$store.commit('modal/setContent',{
              title: 'Payout Info',
              open: true,
              payout: true,
              item: item,
            })
            return true;
        },
        parseDateCol(date) {
            let parsedDate = moment(date, 'YYYY-MM-DD HH:mm:ss');
            return parsedDate.format('YYYY-MM-DD');
        }
    },
    watch: {
    },
    created() {
        this.the_id = this.genId()
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
      Button
    },
}
</script>
<style scoped>
</style>
