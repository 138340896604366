import axios from 'axios'
import Cookies from 'js-cookie';
import Vue from 'vue';

const getDefaultState = () => ({
  user: false,
  roles: {},
  token: false,
  searches: [],
  supabase: {
    token: false,
  },
  login_as_user_link: false,
  loading_login_as_user: false
});
export default {
  namespaced: true,
  state: {
    user: false,
    roles: {},
    token: false,
    searches: Cookies.get('oada_user_searches'),
    supabase: {
      token: false,
    },
    login_as_user_link: false,
    loading_login_as_user: false
  },
  mutations: {
    setState(state, payload) {
      Vue.set(state, payload.key, payload.value);
    },
    resetState(state) {
      Object.assign(state, getDefaultState());
    },
  },
  actions: {
    loginAsUser({ dispatch, commit, state, rootState}, payload) {
      state.loading_login_as_user = true;

      //needed to bypass cors issues
      axios.defaults.withCredentials = false;

      let headers = {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Accept': '*/*'
      }

      axios.post(`${rootState.data.ssoApi}api/v1/login_as_customer`, 
        {
          customer_id: payload.customer_id,
          admin_user_token: state.token 
        },
        {headers: headers}).then((res) => {
        if (res.data.success) {
          console.log('success!', res.data)
          state.login_as_user_link = rootState.data.dashboard + '?oada_auth=' + res.data.token;
          state.loading_login_as_user = false;

        } else {
          console.log('failure...', res.data)
          state.loading_login_as_user = false;
        }
      })

      //turn this back to default setting 
      axios.defaults.withCredentials = true;
    },
    resetState({ commit }) {
      commit('resetState');
    },
    Logout({ dispatch }) {
      Cookies.remove('oada_user_nav_history');
      dispatch('resetState');
    },
  },
  getters: {
    isAuthenticated: (state) => state.user,
  },
};
