<template>
    <div v-if="remediation_transaction || initial_transaction">
        <div v-if="!loading" >
            <dt class="text-sm font-medium text-gray-500">
                Date: {{time_transaction_submitted}}
            </dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 pl-5 font-medium">
                ID: {{transaction_id}} - Response: {{transaction_status}}
            </dd>  
        </div>
        <div v-else>
            Loading..
        </div>
    </div>

    <div v-else>
        <dt class="text-sm font-medium text-gray-500">
            Date: {{time_submitted}}
        </dt>
        <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 pl-5">
            ID: {{transaction_id}} - Attempt Number: {{transaction_attempts}} - Payment Number: {{payment_number}} - Response: {{status}}
        </dd>   
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios'

export default {
    name: 'TransactionDetails',
    data: () => ({
        loading: true,
        time_transaction_submitted: false,
        transaction_status: false,
    }),
    computed: {
        
    },
    props: [
        'transaction_id',
        'time_submitted',
        'transaction_attempts',
        'payment_number',
        'status',
        'remediation_transaction',
        'initial_transaction'
    ],
    methods: {
        getTransactionDetails(transaction_id) {
            this.loading = true;
            
            axios.get(`${this.$store.state.data.baseUrl}/api/billing/get_transaction_details/${transaction_id}`)
                .then((res) => {
                    if ( res.data.success ) {
                        console.log('sucess!', res.data)
                        this.time_transaction_submitted = res.data.transaction_details.submitTimeLocal;
                        this.transaction_status = res.data.transaction_details.transactionStatus;
                        this.loading = false;
                    } else {
                        console.log('failure..', res.data)
                        this.loading = false;
                    }
                })
        },
    },
    watch: {
    },
    created() {
        let token = this.$store.state.user.token;
        if (token) {
            Vue.prototype.$http.defaults.headers.common.Authorization = `Bearer ${token}`;
        }

        if ( this.remediation_transaction || this.initial_transaction ) {
            this.getTransactionDetails(this.transaction_id);
        }
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
    