<template>
    <div class="mt-3 ml-5">
        <a 
            v-if="has_invoice" 
            class="bg-red p-2 text-white" 
            :href="`${$store.state.data.baseUrl}/billing/view_invoice/${transaction_id}`" 
            target="_blank" 
        >
            <span v-if="!loading">
                View Invoice
            </span>
            <span v-else>
                Loading..
            </span>
        </a>
        <button 
            v-else
            @click="generateInvoice()"
            class="bg-red p-2 text-white"
        >
            <span v-if="!loading">
                Generate Invoice
            </span>
            <span v-else>
                Loading..
            </span>
        </button>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from 'axios'

export default {
    name: 'InvoiceBtn',
    data: () => ({
        loading: true,
        has_invoice: false
    }),
    computed: {
        
    },
    props: [
        'transaction_id',
        'subscription_id',
        'account_id'
    ],
    methods: {
        checkForInvoice(transaction_id) {
            this.loading = true;
            
            axios.get(`${this.$store.state.data.baseUrl}/api/billing/get_invoice/${transaction_id}`)
                .then((res) => {
                    if ( res.data.success ) {
                        console.log('sucess!', res.data)

                        this.has_invoice = true;
                        this.loading = false;
                    } else {
                        console.log('failure..', res.data)
                        this.loading = false;
                    }
                })
        },
        generateInvoice() {
            this.loading = true;
            
            axios.post(`${this.$store.state.data.baseUrl}/api/billing/generate_invoice`, {
                transaction_id: this.transaction_id,
                subscription_id: this.subscription_id,
                account_id: this.account_id
            })
                .then((res) => {
                    if ( res.data.success ) {
                        console.log('sucess!', res.data)

                        this.has_invoice = true;
                        this.loading = false;
                    } else {
                        console.log('failure..', res.data)
                    }
                })
        }
    },
    watch: {
    },
    created() {
        let token = this.$store.state.user.token;
        if (token) {
            Vue.prototype.$http.defaults.headers.common.Authorization = `Bearer ${token}`;
        }

        if ( this.transaction_id ) {
            this.checkForInvoice(this.transaction_id);
        }
    },
    beforeDestroy() {
    },
    mounted() {
    },
    components: {
    },
}
</script>
<style scoped>
</style>
    